import React, {useContext, useEffect, useRef, useState} from 'react';
import {lang} from '../../common/baseData';
import Lang from '../Lang/Lang';

function FullDataMobile(props) {
  const fullDataItem = props.item;

  return (
    <div className="border-grey-10 border border-solid py-13">
      <div className="flex flex-col py-4">
        <div className="flex flex-col items-center gap-3">
          <span
            className="text-[16px] leading-[24px] font-medium">
            {fullDataItem[`title_${lang}`]}
          </span>
          <span className="text-[14px] leading-[21px]">
            {fullDataItem[`text_${lang}`]}
          </span>
        </div>
        <div className="grid-cols-2 grid">
          <img src={fullDataItem.left_side.src} alt="photo"
               loading="lazy"
               className="w-full max-w-[100%]"/>
          <img src={fullDataItem.right_side.src} alt="photo"
               loading="lazy"
               className="w-full max-w-[100%]"/>
        </div>
        <a href={fullDataItem.link}
           className="w-[calc(90%)] bg-blue-primary text-white h-[56px] flex items-center justify-center m-auto rounded-2 mt-3">
          {Lang.getMessage('detail')}
        </a>
      </div>
    </div>
  );
}

export default FullDataMobile;