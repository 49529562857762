import { axiosConfig, eventEmitter, lang } from '../common/baseData'
import axios from 'axios'

class Basket {
  static instance

  constructor() {

  }

  static getInstance() {
    if (!this.instance) this.instance = new Basket()
    return this.instance
  }

  add (product_id, main_product_id = null) {
    return new Promise(resolve => {
      axios.post('/local/api/basket/add', { productId: product_id, mainProductId: main_product_id }, axiosConfig)
        .then(response => {
          if (response && response.hasOwnProperty('data')) {
            // отправка события смены состояния корзины
            if (response.data.hasOwnProperty('success') && response.data.success)
              eventEmitter.emit('basketStateChanged', {action: 'add', productId: product_id, mainProductId: main_product_id })
            resolve(response.data)
          } else resolve(false)
        })
        .catch(reason => {
          console.log(reason)
          resolve(false)
        })
    })
  }
}

export default Basket