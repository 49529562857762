import React from 'react';
import Product from '../Product/Product';

function ProductSlider(props) {
  const {title, totalTitle, totalLink, children} = props;

  return (
    <section className={`my-10`}>
      <div
        className="px-5 pb-3 flex justify-between items-baseline desktop:justify-normal desktop:gap-3 desktop:px-0">
            <span
              className="text-[16px] leading-[24px] font-medium text-grey-90 desktop:text-[24px] desktop:leading-[36px]">
                {title}
            </span>
        <a href={totalLink}
           className="inline-block w-auto text-blue-primary text-[14px] leading-[21px] underline desktop:text-[16px] desktop:leading-[24px] hover:no-underline">
          {totalTitle}
        </a>
      </div>
      <ul
        className="px-5 desktop:px-0 flex overflow-x-scroll scroll-smooth snap-x snap-mandatory overscroll-x-none invisible-scrollbar">
        {children}
      </ul>
    </section>
  );
}

export default ProductSlider;