import React, {useContext, useEffect, useRef, useState} from 'react';
import Lang from '../Lang/Lang';
import {ClientWidthContext} from '../Context/ApplicationContextProvider';
import {
  axiosInstance,
  eventEmitter,
  sessid,
  lang,
  apiBaseUrl, underlineClass, hoverUnderlineClass, hoverBtnClass,
} from '../../common/baseData';

function AbandonedCart(props) {
  const clientWidth = useContext(ClientWidthContext);

  // Получение cookies
  const getCookie = (name) => {
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.trim().split('=');
      acc[key] = value;
      return acc;
    }, {});
    return cookies[name];
  };

  // Инициализация состояния видимости корзины из cookies
  const hiddenUntil = parseInt(getCookie('abandonedCartHiddenUntil'), 10) || 0;
  const currentTime = Math.floor(Date.now() / 1000);
  const initialCartVisible = hiddenUntil <= currentTime;

  const [state, setState] = useState(window.abandonedCartData || {
    items: null,
    price: null,
    titleItemsCountLang: '',
    firstItem: null,
    orderLink: '#',
  });

  const [isCartVisible, setCartVisible] = useState(initialCartVisible); // Состояние видимости корзины

  const url = `${apiBaseUrl}basket/abandoned`;

  // Установка значения cookies
  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    document.cookie = `${name}=${value}; path=/; expires=${expires.toUTCString()}`;
  };

  // basket update event
  useEffect(() => {
    eventEmitter.on('basketUpdated', getBasketState);

    return () => {
      eventEmitter.off('basketUpdated', getBasketState);
    }
  }, []);

  // Loading component data
  function getBasketState() {
    axiosInstance.post(url).then(response => response.data).then(response => {
      setState({
        ...state,
        firstItem: response.firstItem,
        items: response.items,
        price: response.price,
        titleItemsCountLang: response.titleItemsCountLang,
        orderLink: response.orderLink,
      });
    });
  }

  // Функция для закрытия корзины
  function closeAbandonedCart(e) {
    e.preventDefault();
    setCartVisible(false); // Устанавливаем видимость в false
    const nextWeek = Math.floor(Date.now() / 1000) + (7 * 24 * 60 * 60); // Текущее время + 7 дней (UNIX timestamp)
    setCookie('abandonedCartHiddenUntil', nextWeek, 7); // Сохраняем в cookie
  }

  const isDesktop = clientWidth >= 1280;
  const heedClampTitle = Array.isArray(state.items) && state.items.length > 2;
  const desktopClass = `${heedClampTitle
    ? 'max-w-[116px] line-clamp-2 overflow-hidden desktop:w-24 desktop-max:w-28 '
    : ''} font-normal text-[12px] leading-[18px] text-grey-90 ${hoverUnderlineClass}`;

  // Условие отображения корзины
  const shouldRenderCart = !Array.isArray(state.items) || !state.items.length || !isCartVisible;

  return (
    !shouldRenderCart && (
      <div className="relative mb-5 mx-5 pr-7 pl-3 py-3 desktop:mx-0 flex justify-between items-center desktop:border-none desktop:pr-12 desktop:pl-0 desktop:py-0">
        {Array.isArray(state.items) && state.items.length > 0 && (
          <>
            <ul
              className="desktop:order-2 flex gap-2 overflow-x-scroll scroll-smooth snap-x snap-mandatory overscroll-x-none invisible-scrollbar cursor-col-resize">
              {
                isDesktop ?
                  state.items.map((item, index) => (
                    <li key={`mobile-abandoned-item-${item.recId}`}
                        className="flex gap-2 items-center">
                        <span
                          className="flex items-center justify-center border border-solid border-grey-10 h-10 w-10">
                          <img src={item.image}
                               width="32px"
                               height="32px"
                               loading="eager"/>
                        </span>
                      <a href={item.link}
                         className={desktopClass}>
                        {item.name}
                      </a>
                    </li>
                  )) :
                  state.firstItem && (
                    <li
                      key={'mobile-abandoned-item'}
                      className="flex items-center justify-center border border-solid border-grey-10 h-10 w-10">
                      <img src={state.firstItem.image}
                           width="32px"
                           height="32px"
                           loading="eager"/>
                    </li>
                  )
              }
            </ul>
            <span
              className="text-grey-90 font-normal text-[14px] leading-[21px] desktop:text-[13px] desktop:leading-[20px] desktop:order-1 shrink-0 desktop:mr-[50px] 400:text-[12px] 400:leading-[16px] 400:max-w-[80px]">
                {state.titleItemsCountLang}
            </span>
            <a href={state.orderLink}
               className={`relative bg-blue-primary px-4 text-white text-[14px] leading-[21px] py-3 border-0 rounded-2 desktop:order-3 desktop:ml-2 400:text-[12px] 400:leading-[16px] 400:py-2.5 ${hoverBtnClass}`}>
              {
                isDesktop && state.items.length > 3 && (
                  <span key={'block-overlay'}
                        className={`absolute top-0 left-[-60px] w-[60px] h-full z-2`}
                        style={{background: 'linear-gradient(to right, transparent, #fff)'}}/>
                )
              }
              <span>{Lang.getMessage('makeOrder')}</span>
            </a>
            <a href={"#"} className={`absolute right-0 top-1/2 -translate-y-1/2 border-none desktop:right-4`} onClick={closeAbandonedCart}>
              <i className="ic-close-sm"></i>
            </a>
          </>
        )
      }
    </div>
    )
  );
}

export default AbandonedCart;