import {axiosConfig} from '../common/baseData';

class PhoneValidation {
    constructor() {}

    static getInstance() {
        if (!this._instance) {
            this._instance = new PhoneValidation();
        }
        return this._instance;
    }

    validatePhone(phoneNumber) {
        if (phoneNumber.length <= 4) return false;
        let phoneData = this.validate(phoneNumber);
        return phoneData && phoneData.valid;
    }

    getInfo(phoneNumber) {
        let response = null;

        try {
            let request = new XMLHttpRequest();
            request.open("POST", '/local/api/phonenumber/getInfo', false); // `false` makes the request synchronous

            for (let key in axiosConfig.headers) {
                request.setRequestHeader([key], axiosConfig.headers[key]);
            }

            request.onload = function () {
                if (request.readyState === 4) {
                    if (request.status === 200) {
                        response = JSON.parse(request.responseText);
                    } else {
                        console.error(request.statusText);
                    }
                }
            };

            request.send(JSON.stringify({
                phoneNumber: phoneNumber
            }));
        } catch (reason) {
            console.error(reason);
        }

        return response;
    }

    validate(phoneNumber) {
        let response = null;

        try {
            let request = new XMLHttpRequest();
            request.open("POST", '/local/api/phonenumber/validate', false); // `false` makes the request synchronous

            for (let key in axiosConfig.headers) {
                request.setRequestHeader([key], axiosConfig.headers[key]);
            }

            request.onload = function () {
                if (request.readyState === 4) {
                    if (request.status === 200) {
                        response = JSON.parse(request.responseText);
                    } else {
                        console.error(request.statusText);
                    }
                }
            };

            request.send(JSON.stringify({
                phoneNumber: phoneNumber
            }));
        } catch (reason) {
            console.error(reason);
        }

        return response;
    }

    getCountryCode(rawPhoneNumber) {
        let phoneData = this.getCountryCodeInfo(rawPhoneNumber);
        return phoneData && phoneData.countryCode;
    }

    getCountryCodeInfo(phoneNumber) {
        let response = null;

        try {
            let request = new XMLHttpRequest();
            request.open("POST", '/local/api/phonenumber/getCountryCode', false); // `false` makes the request synchronous

            for (let key in axiosConfig.headers) {
                request.setRequestHeader([key], axiosConfig.headers[key]);
            }

            request.onload = function () {
                if (request.readyState === 4) {
                    if (request.status === 200) {
                        response = JSON.parse(request.responseText);
                    } else {
                        console.error(request.statusText);
                    }
                }
            };

            request.send(JSON.stringify({
                phoneNumber: phoneNumber
            }));
        } catch (reason) {
            console.error(reason);
        }

        return response;
    }

    clearPhone(phoneNumber) {
        // Check symbols
        if (phoneNumber.match(/[^0-9]+/g) !== null) {
            phoneNumber = phoneNumber.replace(/[^0-9]+/g, '');
        }
        // Check length
        if (phoneNumber.length > 15) {
            phoneNumber = phoneNumber.substring(0, 16);
        }
        return phoneNumber;
    }
}

export { PhoneValidation }