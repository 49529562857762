import React, {useContext, useEffect, useState} from 'react';
import Icon from '../Icon/Icon';
import Lang from '../Lang/Lang';
import './Subscribtion.css';
import {
  ClientWidthContext,
  UserContext,
  SiteContext,
} from '../Context/ApplicationContextProvider';

import {apiBaseUrl, esputnikApi, hoverBtnClass} from '../../common/baseData';

function Subscribtion(props) {
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(true);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);
  const siteSettings = useContext(SiteContext);
  const clientWidth = useContext(ClientWidthContext);
  const isMobile = clientWidth < 1280;

  const groupId = 170409799;
  const pattern = /^([a-zA-Z0-9_.+-])+(\@([a-zA-Z0-9-])+\.)+.+([a-zA-Z0-9])+$/;

  useEffect(() => {
    if (email.length < 4) return;
    setValid(pattern.test(email));
  }, [email]);

  /**
   * Логика подписки
   *
   * @param e
   */
  async function onSubsribe(e) {
    e.preventDefault();
    console.log('onSubsribe', email);

    setLoading(true);

    // данные контакта
    let contactData = null;
    // базовые данные контакта из поиска
    let foundedContact = null;

    // поиск контакта в esputnik
    let foundedContacts = await esputnikApi.findByEmail(email);
    console.log('findByEmail', 'foundedContacts', foundedContacts);

    if (Array.isArray(foundedContacts) && foundedContacts.length > 0) {
      foundedContact = foundedContacts[0];
      contactData = await esputnikApi.getByExtId(foundedContact.id);
    } else {
      // необходимо создать контакт
      let createdContact = await esputnikApi.createContactByEmail(email);

      if (createdContact.hasOwnProperty('id')) {
        contactData = await esputnikApi.getByExtId(createdContact.id);
      }
    }

    console.log('findByEmail', 'contactData', contactData);

    let espGroupIds = [];

    if (contactData.hasOwnProperty('groups')) {
      espGroupIds = contactData.groups.map(group => group.id);
    }

    console.log('espGroupIds', espGroupIds);

    // проверяем вхождение в группу
    if (espGroupIds.includes(groupId)) {
      // подписан ранее
      setResult('subscribedEarly');
    } else {
      // не входит, не подписан, отправляем событие
      let response = await esputnikApi.subscribe(email, contactData.id);
      console.log('subscribe response', response);
      setResult('subscribedNow');
    }
    setLoading(false);
  }

  return (
    <>
      {
        loading && (
          <div id="overlay"
               className="loading-overlay absolute inset-0 bg-grey-overlay flex items-center justify-center">
            <div className="envelope-container relative">
              <Icon name={'envelope-icon'} className="w-12 h-12 animate-fly fill-blue-primary text-blue-primary"/>
            </div>
          </div>
        )
      }
      <div
        className="flex flex-col gap-2 desktop:flex-row desktop:gap-8 desktop:h-15 desktop:items-center desktop:px-31 desktop:w-full">
        {
          ['subscribedNow', 'subscribedEarly'].includes(result) && (
            <div
              className={'flex flex-col gap-2 w-full items-center justify-center'}>
              {
                result !== null && result === 'subscribedEarly' && (
                  <>
                  <span
                    className={'text-[16px] leading-[24px] font-medium text-grey-90 desktop:text-[24px] desktop:leading-[36px]'}>
                    {Lang.getMessage('subscribedEarlyTitle')}
                  </span>
                    <span
                      className={'text-center text-[14px] leading-[21px] text-grey-90 font-medium desktop:mt-3 desktop:text-[16px] desktop:leading-[24px]'}>
                    {Lang.getMessage('subscribedEarlyText')}
                  </span>
                  </>
                )
              }
              {
                result !== null && result === 'subscribedNow' && (
                  <>
                  <span
                    className={'text-[16px] leading-[24px] font-medium text-grey-90 desktop:text-[24px] desktop:leading-[36px]'}>
                    {Lang.getMessage('subscribedNowTitle')}
                  </span>
                    <span
                      className={'text-center text-[14px] leading-[21px] text-grey-90 font-medium desktop:mt-3 desktop:text-[16px] desktop:leading-[24px]'}>
                    {Lang.getMessage('subscribedNowText').
                      replace('{email}', email)}
                  </span>
                  </>
                )
              }
            </div>
          )
        }
        {
          result === null && (
            <>
              <div
                className="flex gap-2 justify-between desktop:gap-8 items-center">
        <span className="w-[66px] desktop:w-[190px]">
          {
            isMobile ?
              <img src={siteSettings.mobileLogo} alt="logo" className={`w-full h-[41px]`}/> :
              <img src={siteSettings.desktopLogo} alt="logo" className={`w-full h-[41px]`}/>
          }
        </span>
                <div className="flex flex-col">
          <span
            className="text-[16px] leading-[24px] font-medium desktop:text-[24px] desktop:leading-[36px]">
            {Lang.getMessage('Subscribe to the newsletter')}
          </span>
                  <span
                    className="text-[14px] leading-[21px] desktop:text-[16px] desktop:leading-[24px]">
            {Lang.getMessage('and withdraw 150 UAH for your first purchase')}
          </span>
                </div>
              </div>
              <form onSubmit={onSubsribe}
                    className="flex flex-col gap-3 desktop:flex-row desktop:grow desktop:gap-0">
                <input name="email" type="email" placeholder="Ваш Email"
                       value={email}
                       onChange={e => (setEmail(e.target.value))}
                       className={`${!valid
                         ? 'border-red-mistake'
                         : 'border-grey-20'} p-3 border border-solid h-[45px] text-[14px] leading-[21px] font-medium placeholder:text-grey-20 desktop:h-14 desktop:w-full`}/>
                <button type="submit"
                        className={`flex items-center justify-center w-full h-14 bg-blue-primary border-0 rounded-2 grow-0 desktop:w-auto desktop:p-4 ${hoverBtnClass}`}>
                  <span className="text-[16px] leading-[24px] text-white font-medium">
                    {Lang.getMessage('Subscribe')}
                  </span>
                </button>
              </form>
            </>
          )
        }
      </div>
    </>
  );
}

export default Subscribtion;