import React from 'react';
import Lang from '../Lang/Lang';
import Icon from '../Icon/Icon';

function SeoText(props) {
  const {h1, text} = props;
  const [opened, setOpened] = React.useState(false);

  return(
    <section
      className={`px-5 desktop:px-0 my-10 desktop:my-16 description-block`}>
      <div className={`content ${opened ? '' : 'closed'} relative`}>
        {
          h1 && (
            <h1>{h1}</h1>
          )
        }
        <p dangerouslySetInnerHTML={{__html: text.trim()}}/>
      </div>
      <div className="mt-[10px] flex gap-3 align-center" onClick={(event) => (setOpened(!opened), event.stopPropagation())}>
        <span className="h-[24px] w-[24px] flex items-center justify-center">
          <Icon name={'dropdown-arrow'} className={`w-[13px] h-[8px] fill-grey-90 ${opened ? 'rotate-180' : ''}`}/>
        </span>
        <button type="button"
                className="text-[14px] leading-[21px] text-grey-90 border-0 bg-transparent underline underline-offset-4 p-0 desktop:no-underline desktop:font-medium">
          {opened ? Lang.getMessage('collapse') : Lang.getMessage('detail')}
        </button>
      </div>
    </section>
  );
}

export default SeoText;
