import React, {useEffect, useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import {eventEmitter} from '../../common/baseData';
import {
  ApplicationContextProvider
} from '../Context/ApplicationContextProvider';

function Layout(props) {
  const {page, children} = {...props};

  return (
    <ApplicationContextProvider>
      <Header page={page}/>{children}<Footer page={page}/>
    </ApplicationContextProvider>
  );
}

export default Layout;