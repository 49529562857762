import React, {useContext, useEffect, useRef, useState} from 'react';
import {hoverBtnClass, lang} from '../../common/baseData';
import Lang from '../Lang/Lang';

function FullDataThreeCols(props) {
  const fullDataItem = props.item;

  return (
    <div className="grid-cols-3 grid">
      <img src={fullDataItem.left_side.src} alt="photo"
           loading="lazy"
           width="100%"
           height="auto"
           className="self-end max-w-[100%]"/>
      <div
        className="flex flex-col items-center justify-center relative">
        <span
          className="text-[24px] text-black leading-[36px] font-medium block ">
          {fullDataItem[`title_${lang}`]}
        </span>
        <span
          className="text-[20px] leading-[30px] text-grey-90 block ">
          {fullDataItem[`text_${lang}`]}
        </span>
        <a href={fullDataItem.link}
           className={`mt-6 bg-blue-primary rounded-sm h-[56px] w-[153px] flex items-center justify-center self-center justify-self-center ${hoverBtnClass}`}>
          <span
            className="text-white text-[16px] leading-[24px] font-medium">
            {Lang.getMessage('detail')}
          </span>
        </a>
      </div>
      <img src={fullDataItem.right_side.src} alt="photo"
           loading="lazy"
           width="100%"
           height="auto"
           className="self-end max-w-[100%]"/>
    </div>
  );
}

export default FullDataThreeCols;