import React, {useContext, useEffect} from 'react';
import {hoverBtnClass, lang} from '../../common/baseData';
import Lang from '../Lang/Lang';
import {ClientWidthContext} from '../Context/ApplicationContextProvider';
import FullDataMobile from './FullDataMobile';
import FullDataDesktopThreeCols from './FullDataDesktopThreeCols';
import FullDataDesktopTwoRows from './FullDataDesktopTwoRows';

function LinkingBanners(props) {
  const {items} = props;

  const clientWidth = useContext(ClientWidthContext);
  const isMobile = clientWidth < 1280;

  const [onePhotoItem, setOnePhotoItem] = React.useState(null);
  const [fullDataItem, setFullItemData] = React.useState(null);

  useEffect(() => {
    let tmpOnePhotoItem = null;
    let tmpFullDataItem = null;

    for (let item of items) {

      if (item.hasOwnProperty('one_photo') &&
        item.one_photo.hasOwnProperty('src') &&
        tmpOnePhotoItem === null) {
        tmpOnePhotoItem = item;
        continue;
      }

      if (item.hasOwnProperty('left_side') &&
        item.left_side && item.left_side.src &&
        item.hasOwnProperty('right_side') &&
        item.right_side && item.right_side.src &&
        tmpFullDataItem === null) {
        tmpFullDataItem = item;
      }
    }

    setOnePhotoItem(tmpOnePhotoItem);
    setFullItemData(tmpFullDataItem);
  }, [items]);

  const colsClass = fullDataItem !== null && onePhotoItem !== null ? 'basis-1/2' : '';

  return (
    <section className={`my-10 px-5 desktop:px-0`}>
      <div className={`flex gap-5 ${isMobile ? 'flex-col' : 'flex-row'}`}>
        {
          isMobile && (
            <>
              {
                fullDataItem !== null && (
                  <FullDataMobile item={fullDataItem}/>
                )
              }
              {
                onePhotoItem !== null && (
                  <a href={onePhotoItem.link} className="block object-cover">
                    <img src={onePhotoItem.one_photo.src} alt="photo"
                         loading="lazy"
                         width={'100%'}
                         height={'100%'}
                         className="max-w-[100%]"/>
                  </a>
                )
              }
            </>
          )
        }
        {
          !isMobile && (
            <>
              {
                fullDataItem !== null ?
                  onePhotoItem === null ?
                    <FullDataDesktopThreeCols item={fullDataItem}/> :
                    <FullDataDesktopTwoRows item={fullDataItem}/> :
                  <></>
              }
              {
                onePhotoItem !== null && (
                  <a href={onePhotoItem.link} className={`block ${colsClass}`}>
                    <img src={onePhotoItem.one_photo.src} alt="photo"
                         loading="lazy"
                         width={'100%'}
                         height={'100%'}
                         className="w-full max-w-[100%] object-cover"/>
                  </a>
                )
              }
            </>
          )
        }
      </div>
    </section>
  );
}

export default LinkingBanners;